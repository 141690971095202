<div class="terms">
  <div class="back">
    <a class="back" routerLink="">Назад</a>
  </div>
  <div class="main-text">
    <h2>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ LINEA – ВАШИ МЕСТА НА ЗЕМЛЕ</h2>
    <p>г. Варшава «17» августа 2021</p>
    <p>
      Настоящее Пользовательское соглашение (далее – Соглашение) является юридически обязываемым договором, заключаемым между Вами (Пользователем) и МИКСОРТ (как указано далее), по использованию Программы.
    </p>
    <p>
      Внимательно ознакомьтесь с условиями Соглашения перед установкой Программы на свое устройство и его использованием.
    </p>
    <p>
      Заключение Соглашения на изложенных в нем условиях (присоединение к Соглашению) производится путем скачивания Программы в Google Play или Apple Store и использованием Программы после ее установки, что означает ознакомление и полное безоговорочное принятие Пользователем условий, изложенных в настоящем Соглашении, в редакции, действующей на момент выражения своего согласия на использование Программы, а равно заключение Соглашения путем присоединения к нему
    </p>
    <p>
      Использование Программы допустимо исключительно на условиях настоящего Соглашения. Если Пользователь не принимает условия Соглашения в полном объёме, он не имеет права использовать Программу и обязуется прекратить ее использование.
    </p>

    <h3>1. Термины и определения</h3>
    <p>
      <span>Профиль</span> – совокупность данных о Пользователе, предоставляемая им самим, которые используются Программой для ее надлежащей работы.
    </p>
    <p><span>МИКСОРТ</span> – правообладатель Программы.</p>
    <p>
      <span>Пользователь</span> – физическое лицо в возрасте 14 лет и более, обратившееся за
      приобретением лицензии на использование Программы на условиях, оговоренных
      в настоящем Соглашении.
    </p>
    <p>
      <span>Программа</span> – мобильное приложение «Linea – ваши места на Земле», созданное
      ООО «МИКСОРТ» для расчета благоприятных или неблагоприятных мест
      проживания Пользователя, исходя из астрологических прогнозов в зависимости
      от даты и места рождения.
    </p>
    <p>
      <span>Тариф</span> – вознаграждение МИКСОРТ за предоставление Пользователю права
      использования Программы, размер которого указан в Программе.
    </p>
    <h3>2. Общие положения</h3>
    <p>
      2.1 Соглашение определяет общие условия использования Программы, а также права и обязанности Пользователя и МИКСОРТ.
    </p>
    <p>
      2.2 Соглашение может быть изменено и (или) дополнено МИКСОРТ в одностороннем порядке без какого-либо специального уведомления Пользователя. Настоящее Соглашение является открытым и общедоступным документом. Пользователь обязан регулярно проверять условия настоящего Соглашения на предмет его изменения и (или) дополнения. Продолжение использования Программы Пользователем после внесения изменений и (или) дополнений в настоящее Соглашение означает принятие и согласие Пользователя со всеми такими изменениями и (или) дополнениями.
    </p>
    <p>
      2.3 Никакие положения настоящего Соглашения не предоставляют Пользователю прав на использование фирменного наименования, товарных знаков, доменных имен и результатов интеллектуальной деятельности МИКСОРТ, за исключением способов, прямо указанных в Соглашении. Все права на элементы Программы (включая объекты, составляющие интеллектуальную собственность) принадлежат МИКСОРТ и не могут быть использованы отличным от указанного в настоящем Соглашении способом.
    </p>
    <h3>3. Принятие Соглашения</h3>
    <p>
      3.1 Соглашение считается заключенным с момента выражения согласия Пользователя путем использования Программы после ее установки.
    </p>
    <p>
      3.2 При выражении согласия Пользователем как указано выше, Пользователь соглашается с условиями Соглашения, принимает их полностью и безоговорочно, считает себя связанным условиями Соглашения.
    </p>
    <p>
      3.3 Заключая настоящее соглашение, Пользователь подтверждает, что он соответствует полным требованиям законодательства Республики Польша, настоящего Соглашения, а также требований законодательства страны своего гражданства и вправе самостоятельно выступать стороной по настоящему соглашению.
    </p>
    <p>
      3.4 В случае, если Вы не достигли возраста полной дееспособности, включая, но не ограничиваясь: не достигли возраста, требуемого для заключения юридически обязывающих соглашений, находитесь под опекой или попечительством вследствие принятия ограничительных мер компетентным органом, а также в любых иных возможных случаях, когда заключение Соглашения должно быть осуществлено Вашим законным представителем. Используя Приложение, вы подтверждаете, что ваш законный представитель прочитал и принял это Соглашение.
    </p>
    <h3>4. Предмет Соглашения</h3>
    <p>
      4.1 МИКСОРТ предоставляет Пользователю право использования (простую (неисключительную) лицензию) Программы в рамках ее функциональных возможностей путем воспроизведения программы на устройстве Пользователя, исключительно для самостоятельного использования Пользователем без права сублицензирования третьим лицам.
    </p>
    <p>
      4.2 МИКСОРТ предоставляет Пользователю право использования Программы без ограничения по территории в порядке и на условиях, предусмотренных законодательством Республики Польша и настоящим Соглашением.
    </p>
    <p>
      4.3 Право на использование программы считается предоставленным Пользователю, а обязанности МИКСОРТ по предоставлению права на использование Программы исполненными, после установки Программы на устройство Пользователя
    </p>
    <p>
      4.4 Срок предоставления лицензии определяется типом подписки, приобретаемой Пользователем.
    </p>
    <h3>5. Исключительные права</h3>
    <p>
      5.1 Программа является результатом интеллектуальной деятельности МИКСОРТ. Исключительные права на Программу принадлежат МИКСОРТ и защищаются законодательством Республики Польша.
    </p>
    <p>
      5.2 Алгоритмы работы Программы и ее исходные коды (в том числе их части) являются коммерческой тайной МИКСОРТ. Любое их использование либо использование Программы в нарушение условий настоящего Соглашения рассматривается как нарушение прав МИКСОРТ и является достаточным основанием для лишения Пользователя предоставленных по настоящему Соглашению прав.
    </p>
    <p>
      5.3 Настоящим Соглашением Пользователю не предоставляется право собственности на Программу и ее компоненты, а только право использования Программы и ее компонентов в соответствии с условиями, которые обозначены в настоящем Соглашении.
    </p>
    <p>
      5.4 Пользователь не вправе копировать, распространять Программу и его компоненты в любой форме, в том числе в виде исходного кода, каким-либо иным способом, в том числе сдавать в аренду, безвозмездное пользование, либо в прокат.
    </p>
    <p>
      5.5 Пользователь не вправе использовать Программу каким-либо способом, если такое использование противоречит настоящему Соглашению или приводит к нарушению настоящего Соглашения и законодательства Республики Польша.
    </p>
    <p>
      5.6 Ответственность за нарушение авторских прав наступает в соответствии с законодательством Республики Польша.
    </p>
    <h3>6. Использование Программы</h3>
    <p>
      6.1 Для корректного использования Программы, устройство Пользователя, на которое устанавливается Программа, должно соответствовать минимальным требованиям, которыми являются: операционная система iOS 10 или 4.3 Jelly Bean.
    </p>
    <p>
      6.2 Использование Программы допускается только для личного некоммерческого использования. Это право распространяется на одного индивидуального Пользователя на условиях персональной, не ограниченной территориально, отзывной, непередаваемой и неисключительной лицензии на доступ и использование Программы в личных некоммерческих целях в соответствии с условиями настоящего Соглашения.
    </p>
    <p>
      6.3 Пользователь вправе без выплаты вознаграждения использовать Программу в рамках лицензии для ознакомления (пробной версии) в течение 3-х дней с момента начала пробного периода. Пробная версия по усмотрению МИКСОРТ может содержать ограниченный функционал и не предоставлять возможность использовать все возможности Программы.
    </p>
    <p>
      6.4 Дальнейшее использование Программы возможно только при приобретении подписки на Программу.
    </p>
    <p>
      6.5 Пользователь самостоятельно выбирает, приобретать ли ему подписку на Программу и какую подписку на Программу приобретать.
    </p>
    <p>
      6.6 Выбор соответствующей подписки подтверждается Пользователем посредством оплаты стоимости соответствующей подписки.
    </p>
    <p>
      6.7 Используя Программу, Вы соглашаетесь не применять его в любых целях, не разрешенных настоящим Соглашением. Вы несете ответственность за всю свою деятельность в Приложении, и вы должны соблюдать все местные, государственные, национальные и международные законы и правила, а также любые применимые нормативные акты.
    </p>
    <h3>7. Платежи</h3>
    <p>
      7.1 Программа содержит возможность совершения покупок путем приобретения подписки. Информация об условиях подписки, ее стоимости и продолжительности действия содержится в соответствующем элементе интерфейса. Оплата подписки снимается с учетной записи iTunes или Google Play при подтверждении покупки по ценам, указанным для выбранных условий.
    </p>
      7.2 Автоматическое продление подписки – совершая приобретение подписки, Пользователь соглашается на автоматическое продление такого же типа подписки по окончании периода ее действия и списание денежных средств до момента, пока Вы не отмените подписку.
    <p></p>
    <p>
      7.3 С момента приобретения подписки Пользователем, подписка будет автоматически продлеваться на тот же период и на тех же условиях, что были установлены в момент ее приобретения. Подписка автоматически продлевается, если автоматическое продление не отключено по крайней мере за 24 часа до окончания выбранного периода. Плата за продление будет взиматься в течение 24 часов до окончания периода подписки.
    </p>
    <p>
      7.4 Для отмены подписки Вам необходимо воспользоваться своей учетной записью в Google Play Market или iTunes и нажать кнопку «Отменить подписку» в «Личном кабинете» в Разделе «Подписки».
    </p>
    <p>
      7.5 Чтобы избежать любых нежелательных платежей в будущем, Вы должны отменить свою подписку способом, указанным выше, до истечения бесплатного пробного периода или выбранного периода подписки. Обратите внимание, что удаление вашей учетной записи и / или мобильного приложения с вашего устройства не отменяет автоматически вашу подписку.
    </p>
    <p>
      7.6 Во избежание каких-либо расхождений – Вам не будет возмещена уже оплаченная стоимость подписки за текущий период, и Вы сможете продолжать пользоваться Программой до конца текущего периода подписки, за исключением случаев, указанных ниже.
    </p>
    <p>
      7.7 Для пользователей, проживающих в Европейском союзе или Европейской экономической зоне, применяются следующие условия отмены подписки: в соответствии с местным законодательством вы имеете право на полный возврат средств в течение 14 дней после начала подписки. Такой 14-дневный период отмены начинается с момента начала подписки.
    </p>
    <p>
      7.8 Пользователь имеет право сменить тип подписки в любое время в течение всего срока действия подписки. При этом досрочная смена типа подписки допускается только в сторону увеличения срока ее действия. Срок действия оплаченной, но неизрасходованной подписки будет увеличен на срок, приобретаемой подписки с учетом стоимости приобретаемой подписки.
    </p>
    <h3>8. Персональные данные</h3>
    <p>
      8.1 Для корректного использования Программы, от Пользователя может потребоваться предоставление данных о его дате, времени и месте рождения.
    </p>
    <p>
      8.2 МИКСОРТ или Программа не осуществляет сбор или обработку специальных категорий персональных данных, касающихся расового или этнического происхождения, политических взглядов, религиозных или философских убеждений или членства в профсоюзах, а также генетических данных, биометрических данных с целью однозначной идентификации пользователей Программы, данных о здоровье или данных, касающихся сексуальной жизни или сексуальной ориентации Пользователя.
    </p>
    <p>
      8.3 Используя Программу, Пользователь предоставляет согласие на сбор, обработку, хранение и использование персональных данных, предоставленных им, в том числе на передачу своих данных в другую страну.
    </p>
    <p>
      8.4 Полные условия и способы обработки персональных данных Пользователя
      размещены по следующему адресу: https://get-linea.app/privacy
    </p>

    <h3>9. Ограничение ответственности.</h3>
    <p>
      9.1 МИКСОРТ не несет ответственности за нарушение Пользователем настоящего Соглашения и оставляет за собой право по своему усмотрению, а также при получении информации от других Пользователей либо третьих лиц о нарушении Пользователем настоящего Соглашения, изменять (модерировать) и (или) удалять любую публикуемую Пользователем информацию, нарушающую запреты, установленные настоящим Соглашением, приостанавливать, ограничивать или прекращать доступ Пользователя к Программе в любое время по любой причине без объяснения причин, с предварительным уведомлением или без такового, не отвечая за любой вред, который может быть причинен Пользователю такими действиями.
    </p>
    <p>
      9.2 МИКСОРТ не несет ответственности за использование Пользователем Программы или за последствия принятых Пользователем решений при использовании Программы. Пользователь подтверждает, что полностью сознает риск принятия решений и самостоятельно несет ответственность за их принятие и совершение каких-либо действий.
    </p>
    <p>
      9.3 Вы однозначно понимаете и соглашаетесь, что в степени, разрешенной действующим законодательством, Вы используете приложение на ваш исключительный риск, и услуги предоставляются на условиях «как есть» и «по доступности», со всеми ошибками.
    </p>
    <p>
      9.4 МИКСОРТ, его сотрудники, агенты, представители, поставщики, партнеры и поставщики контента не гарантируют: (а) что Программа будет безопасной или доступной в любое конкретное время или в любом регионе; (б) что любые дефекты или ошибки будут исправлены; (в) что любой контент или программное обеспечение, доступные в или через Программу, не содержат вирусов или других вредоносных компонентов; (г) что результаты использования Программы будут соответствовать вашим ожиданиям, требованиям; Вы используете Программу исключительно на собственный риск; (д) точность, достоверность или полноту контента, текстов, изображений, программного обеспечения, графики или сообщений, предоставленных через Программу.
    </p>
    <p>
      9.5 Ни при каких обстоятельствах МИКСОРТ, его должностные лица, директора, агенты, дочерние компании, сотрудники, рекламодатели и поставщики данных не несут ответственности за любые косвенные, специальные, случайные или штрафные убытки (включая, но не ограничиваясь упущенной выгодой, потерей прибыли или потерей данных), будь то в гражданском иске, гражданском правонарушении (включая халатность, но не ограничиваясь ею), капитале компании или ином месте, возникающие из-за использования Программы или каким-либо образом связанные с использованием Программы.
    </p>
    <h3>10. Ограничения использования</h3>
    <p>10.1 Пользователь не вправе совершать действия, которые могут повлечь:</p>
    <p class="sub">
      10.1.1 Нарушение работы Программы или ограничение возможностей других Пользователей в использовании Программы;
    </p>
    <p class="sub">
      10.1.2 Несанкционированный доступ к Программе, а также информационно-вычислительным и сетевым ресурсам МИКСОРТ;;
    </p>
    <p class="sub">
      10.1.3 Причинение либо угрозу причинения ущерба третьим лицам, в том числе путем размещения информации и ссылок на ресурсы сети, содержание которых противоречит законодательству Республики Польша.
    </p>
    <p>
      10.2 Пользователь не имеет права на модификацию Программы, дизайна и внешнего вида.
    </p>
    <p>
      10.3 Пользователь не имеет права использовать Программу иными способами, кроме указанных в настоящем Соглашении, а также копировать, продавать и перепродавать ее или доступ к ней.
    </p>
    <h3>11. Действие настоящего Соглашения</h3>
    <p>
      11.1 Настоящее Соглашение вступает в силу для Пользователя с момента его присоединения к нему и действует в течение неопределенного срока.
    </p>
    <p>
      11.2 Настоящее Соглашение распространяется на все обновления Программы, предоставляемые Пользователю, если только при обновлении Программы не предлагается ознакомиться и принять новое Соглашение или дополнения к настоящему Соглашению.
    </p>
    <p>
     11.3 МИКСОРТ имеет право изменять условия настоящего Соглашения в одностороннем порядке путем размещения измененного текста в сети Интернет на странице: https://get-linea.app/terms.
    </p>
    <p>
     11.4 Если по каким-либо причинам одно или несколько положений настоящего Соглашения будут в установленном порядке признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений настоящего Соглашения.
    </p>
    <p>
      11.5 В случае возникновения любых споров или разногласий, связанных с исполнением настоящего Соглашения, Пользователь и МИКСОРТ приложат все усилия для их разрешения путем проведения переговоров. В случае, если споры не будут разрешены путем переговоров, споры подлежат разрешению в порядке, установленном законодательством Республики Польша, по месту нахождения МИКСОРТ, если иной порядок определения применимого права и (или) места рассмотрения спора не предусмотрен законодательством Республики Польша.
    </p>
    <p>
      11.6 МИКСОРТ имеет право в случае нарушения Пользователем условий настоящего Соглашения по использованию Программы в одностороннем порядке расторгнуть настоящее Соглашение.
    </p>
    <p>
     11.7  При расторжении настоящего Соглашения любой стороной и по любым основаниям Пользователь обязан полностью прекратить использование Программы.
    </p>

    <h3>12. Сведения о МИКСОРТ:</h3>
    <p>
      12.1 Обращения по вопросам условий настоящего Соглашения принимаются на адрес электронной почты МИКСОРТ: info@mixort.com.
    </p>
    <p>
      12.2 МИКСОРТ – общество с ограниченной ответственностью «МИКСОРТ», зарегистрированное в соответствии с законодательством Республики Польша, расположенное по адресу: 0-837 Варшава, Республика Польша, ул. Паньска, 98 /52, номер КРС: 0000698711.
    </p>
    <p>
      12.3 МИКСОРТ не осуществляет деятельность в области азартных игр, деятельности казино, лотерей, рекламных игр и любых других профессиональных видов деятельности, требующих лицензирования или иного законодательного требования.
    </p>
  </div>
</div>
