import { Component } from '@angular/core';
import { style, animate, transition, trigger} from '@angular/animations';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('hand', [
      transition('* => *', [
        style({opacity: 0, bottom: '-100%', }),
        animate(600, style({opacity: 1, bottom: 'calc(-2%-1px)'})),
      ])
    ])
  ]
})
export class AppComponent {
  title = 'linea';
}
