import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [
    trigger('footer', [
      transition('void => *', [
        style({opacity: 0, transform: 'translateY(40%)', }),
        animate(700, style({opacity: 1, transform: 'translateY(0)'})),
      ])
    ])
  ]
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
