<header class="header" [@header]>
  <div class="header-logo-wrapper">
    <svg viewBox="0 0 193 52" fill="none" xmlns="http://www.w3.org/2000/svg" class="logo-linea">
      <path d="M68.8284 26.133L43.4341 51.5461C43.1836 51.7968 42.7774 51.7968 42.5269 51.5461L34.4531 43.4663C34.2026 43.2156 34.2026 42.8091 34.4531 42.5584L59.8474 17.1453C60.0979 16.8946 60.5041 16.8946 60.7546 17.1453L68.8284 25.2251C69.0789 25.4758 69.0789 25.8823 68.8284 26.133Z" fill="url(#paint0_linear)"/>
      <path d="M8.52253 0.453945L0.448736 8.53371C0.198215 8.78442 0.198215 9.1909 0.448736 9.4416L42.5221 51.5461C42.7726 51.7968 43.1787 51.7968 43.4293 51.5461L51.9567 43.0124L9.42974 0.453946C9.17922 0.203239 8.77305 0.203238 8.52253 0.453945Z" fill="url(#paint1_linear)"/>
      <path d="M84.6085 17.6354C85.0209 17.6354 85.3553 17.9701 85.3553 18.383V42.9997H96.2548C96.6672 42.9997 97.0015 43.3345 97.0015 43.7474V50.5786C97.0015 50.9915 96.6672 51.3262 96.2548 51.3262H77.4519C77.0395 51.3262 76.7051 50.9915 76.7051 50.5786V18.383C76.7051 17.9701 77.0395 17.6354 77.4519 17.6354H84.6085Z" fill="white"/>
      <path d="M107.07 23.9404C106.168 24.8388 105.089 25.288 103.832 25.288C102.576 25.288 101.48 24.8388 100.546 23.9404C99.6441 23.0099 99.1931 21.9189 99.1931 20.6676C99.1931 19.4162 99.6441 18.3413 100.546 17.4429C101.48 16.5444 102.576 16.0952 103.832 16.0952C105.089 16.0952 106.168 16.5444 107.07 17.4429C108.004 18.3413 108.471 19.4162 108.471 20.6676C108.471 21.9189 108.004 23.0099 107.07 23.9404ZM107.843 50.5786C107.843 50.9915 107.509 51.3262 107.096 51.3262H100.568C100.156 51.3262 99.8213 50.9915 99.8213 50.5786V28.0089C99.8213 27.596 100.156 27.2613 100.568 27.2613H107.096C107.509 27.2613 107.843 27.5961 107.843 28.0089V50.5786Z" fill="white"/>
      <path d="M127.208 26.5875C129.882 26.5875 132.024 27.502 133.635 29.3309C135.278 31.1278 136.1 33.6626 136.1 36.9354V50.5786C136.1 50.9915 135.765 51.3262 135.353 51.3262H128.824C128.412 51.3262 128.078 50.9915 128.078 50.5786V37.9461C128.078 36.7268 127.756 35.7963 127.111 35.1546C126.467 34.4808 125.581 34.1439 124.453 34.1439C123.197 34.1439 122.214 34.5289 121.506 35.299C120.829 36.037 120.491 37.1119 120.491 38.5237V50.5786C120.491 50.9915 120.156 51.3262 119.744 51.3262H113.216C112.803 51.3262 112.469 50.9915 112.469 50.5786V28.0089C112.469 27.596 112.803 27.2613 113.216 27.2613H119.744C120.156 27.2613 120.491 27.5961 120.491 28.0089V29.6678C121.94 27.6143 124.179 26.5875 127.208 26.5875Z" fill="white"/>
      <path d="M164.358 41.6663C164.293 42.0004 163.993 42.2297 163.654 42.2297H147.559C148.364 44.1869 150.168 45.1656 152.971 45.1656C154.471 45.1656 155.784 44.7324 156.911 43.8659C157.188 43.6529 157.572 43.6231 157.859 43.823L162.517 47.0704C162.87 47.3164 162.944 47.8111 162.656 48.1303C160.324 50.7101 157.031 52 152.778 52C148.686 52 145.4 50.8128 142.919 48.4384C140.471 46.0319 139.247 42.9997 139.247 39.3419C139.247 35.7161 140.455 32.6839 142.871 30.2454C145.287 27.8068 148.364 26.5875 152.101 26.5875C155.709 26.5875 158.689 27.7908 161.041 30.1972C163.393 32.5716 164.569 35.5878 164.569 39.2456C164.569 40.1326 164.499 40.9395 164.358 41.6663ZM147.462 36.6948H156.74C156.128 34.4487 154.614 33.3257 152.198 33.3257C149.717 33.3257 148.138 34.4487 147.462 36.6948Z" fill="white"/>
      <path d="M184.978 29.4753V28.0089C184.978 27.5961 185.312 27.2613 185.725 27.2613H192.253C192.666 27.2613 193 27.5961 193 28.0089V50.5786C193 50.9915 192.666 51.3262 192.253 51.3262H185.725C185.312 51.3262 184.978 50.9915 184.978 50.5786V49.1122C183.335 51.0374 181.048 52 178.116 52C174.862 52 172.156 50.7968 169.997 48.3903C167.839 45.9517 166.76 42.9195 166.76 39.2938C166.76 35.6359 167.839 32.6198 169.997 30.2454C172.156 27.8068 174.862 26.5875 178.116 26.5875C181.048 26.5875 183.335 27.5501 184.978 29.4753ZM176.231 43.096C177.133 44.0586 178.358 44.5399 179.904 44.5399C181.45 44.5399 182.675 44.0586 183.577 43.096C184.511 42.1013 184.978 40.8339 184.978 39.2938C184.978 37.7536 184.511 36.4862 183.577 35.4915C182.675 34.5289 181.45 34.0476 179.904 34.0476C178.358 34.0476 177.133 34.5289 176.231 35.4915C175.297 36.4862 174.83 37.7536 174.83 39.2938C174.83 40.8339 175.297 42.1013 176.231 43.096Z" fill="white"/>
      <defs>
      <linearGradient id="paint0_linear" x1="66.0745" y1="19.9012" x2="31.5052" y2="22.8604" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FFD86C"/>
      <stop offset="1" stop-color="#FE7AA6"/>
      </linearGradient>
      <linearGradient id="paint1_linear" x1="0.260845" y1="0.265915" x2="55.9727" y2="5.05948" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FE7AA6"/>
      <stop offset="1" stop-color="#FFD86C"/>
      </linearGradient>
      </defs>
      </svg>
  </div>
  <div class="header-button-wrapper">
    <a class="store-link apple" href="https://apps.apple.com/us/app/linea-your-places-on-earth/id1577370602" target="_blank">
      <app-button src='../assets/images/corrent.svg' text="App Store"></app-button>
    </a>
    <a class="store-link" href="https://play.google.com/store/apps/details?id=com.mixort.linea" target="_blank">
      <app-button src='../assets/images/google_play.svg' text="Play Store"></app-button>
    </a>
  </div>
</header>
