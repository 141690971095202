import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  animations: [
    trigger('info', [
      transition('void => *', [
        style({opacity: 0, transform: 'translateY(30%)', }),
        animate(700, style({opacity: 1, transform: 'translateY(0)'})),
      ])
    ])
  ]
})
export class InfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
