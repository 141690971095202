<div class="page-wrapper">
  <div class="container">
    <app-header></app-header>
    <div class="body-wrapper">
      <app-info></app-info>
      <div class="footer-block">
        <app-footer></app-footer>
      </div>
    </div>
  </div>
  <img [@hand] class="image-hand" src="../assets/images/3d_hand.png">
</div>
