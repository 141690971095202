<div class="privacy">
  <div>
    <a class="back" routerLink="">Назад</a>
  </div>
  <div class="main-text">
    <h2>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h2>
    <p>г. Варшава «17» августа 2021 г.</p>
    <p>ПРЕАМБУЛА</p>

    <p>
      Настоящая Политика конфиденциальности устанавливает обязательства МИКСОРТ
      по неразглашению и обеспечению защиты конфиденциальности предоставляемых
      пользователями Программы персональных данных. Мы обязуемся защищать и
      уважать ваши права на защиту ваших личных данных. Одним из принципов нашей
      работы с персональными данными является целостность вашей персональной
      информации. Мы обязуемся обеспечивать сохранение вашей персональной
      информации в секрете, предотвращать ее раскрытие без вашего
      предварительного письменного разрешения, а также не продавать, обменивать,
      публиковать или раскрывать любым другим возможным способом, если мы не
      обязаны делать это в силу какого-либо акта законодательства.
    </p>

    <p>
      Настоящая Политика предназначена для информирования вас о том, как мы
      собираем, используем и защищаем информацию, полученную от вас через
      Программу, которое принадлежит МИКСОРТ.
    </p>

    <p>
      Мы используем ваши персональные данные только для целей, указанных в этом
      документе. Пожалуйста, прочтите эту Политику конфиденциальности перед
      использованием нашего приложения.
    </p>

    <p>
      ЕСЛИ ВЫ НЕ ПРИНИМАЕТЕ УСЛОВИЯ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ, МЫ ПРОСИМ ВАС
      НЕ ИСПОЛЬЗОВАТЬ ПРИЛОЖЕНИЕ И НЕЗАМЕДЛИТЕЛЬНО ВЫЙТИ ИЗ НЕГО.
    </p>

    <p>
      Подтвердив свое ознакомление с Политикой конфиденциальности путем
      использования Программы, вы также:
    </p>

    <p>
      в полном объеме принимаете условия настоящего документа без каких-либо
      изъятий и оговорок;
    </p>
    <p>
      подтверждаете, что проинформированы и согласны с тем, что ваше согласие,
      данное в электронной форме, является согласием, полностью отвечающем
      требованиям законодательства о персональных данных и позволяющим
      подтвердить факт его получения МИКСОРТ.
    </p>
    <p>
      соглашаетесь с тем, что предоставленные вами данные могут быть переданы в
      страну, отличную от страны вашего гражданства, для целей, предусмотренных
      настоящей Политикой конфиденциальности
    </p>
    <p>
      Обратите внимание, что согласие на использование данной Политики
      ограничивается только Программой, и не распространяется на другие
      интернет-ресурсы.
    </p>

    <h3>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h3>

    <p>
      МИКСОРТ – общество с ограниченной ответственностью «МИКСОРТ»,
      зарегистрированное в соответствии с законодательством Республики Польша,
      расположенное по адресу: 0-837 Варшава, Республика Польша, ул. Паньска, 98
      /52, номер КРС: 0000698711.
    </p>
    <p>
      Обработка персональных данных – любое действие (операция) или совокупность
      действий (операций), совершаемых с использованием средств автоматизации
      или без использования таких средств с персональными данными, включая сбор,
      запись, систематизацию, накопление, хранение, уточнение (обновление,
      изменение), извлечение, использование, передачу (распространение,
      предоставление, доступ), обезличивание, блокирование, удаление,
      уничтожение персональных данных.
    </p>
    <p>
      Персональные данные (персональная информация, личные данные) – любая
      информация, относящаяся прямо или косвенно к определенному или
      определяемому физическому лицу. Анонимная информация, которую мы не можем
      связать с вашей личностью, не относится к категории «персональные данные».
    </p>
    <p>
      Пользователь – физическое лицо в возрасте 14 лет и более, обратившееся за
      приобретением лицензии на использование Программы на условиях, оговоренных
      в настоящем Соглашении.
    </p>
    <p>
      Пользовательское соглашение – договор, заключаемый между МИКСОРТ и
      Пользователем, размещенный по адресу
      <a routerLink="/terms">Пользовательское соглашение</a>
    </p>
    <p>
      Программа – мобильное приложение «Linea», созданное ООО «МИКСОРТ» для
      расчета благоприятных или неблагоприятных мест проживания Пользователя,
      исходя из астрологических прогнозов в зависимости от даты и места
      рождения.
    </p>
    <h3>ОБЪЕМ РЕГУЛИРОВАНИЯ НАСТОЯЩЕЙ ПОЛИТИКИ</h3>
    <p>
      Настоящая Политика конфиденциальности применяется к любой информации,
      собираемой или отправляемой с использованием Программы и относящейся к
      настоящей Политике конфиденциальности.
    </p>
    <p>
      Мы не собираем и не обрабатываем ваши данные, за исключением тех, что
      необходимы нам для эффективной работы Программы и указаны в настоящей
      Политике.
    </p>
    <p>
      Мы не собираем и не обрабатываем особые категории персональных данных,
      касающихся расового или этнического происхождения, политических взглядов,
      религии или философских убеждений, членства в профсоюзах, а также
      обработки генетических данных, данных о здоровье или данных, касающихся
      половой жизни или уголовных приговоров и правонарушений или
      соответствующие меры безопасности.
    </p>
    <p>
      Мы понимаем, что существуют особые обязательства по защите личных данных
      детей. Мы сознательно не собираем личную информацию от детей в возрасте до
      14 лет без согласия родителей ребенка или законного опекуна. Программа не
      предназначена для лиц младше 14 лет. Если нам станет известно, что ребенок
      в возрасте до 14 лет предоставил нам личные данные без согласия своего
      родителя или законного представителя, мы предпримем все меры, доступные
      для удаления этих личных данных. Если вы знаете, что ребенок предоставил
      нам личные данные, свяжитесь с нами через нашу службу поддержки по адресу
      linea@mixort.com .
    </p>
    <h3>ПРАВОВАЯ ОСНОВА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
    <p>
      Мы собираем и обрабатываем информацию о Вас только в тех случаях, когда у
      нас есть правовые основания для этого в соответствии с действующим
      законодательством. Мы собираем и используем вашу информацию только в тех
      случаях, когда:
    </p>
    <p>
      Вы дали согласие на обработку ваших личных данных для одной или нескольких
      целей, указанных в настоящей Политике.
    </p>
    <p>
      Обработка обязательна для соблюдения акта законодательства или исполнения
      обязательства по решению органа публичной власти.
    </p>
    <p>Обработка необходима для защиты ваших интересов.</p>
    <p>
      Обработка обязательна для осуществления официальных полномочий,
      предоставленных нам.
    </p>
    <p>
      Обработка необходима для иных законных интересов, преследуемых нами или
      третьей стороной, за исключением случаев, когда такие интересы нарушают
      ваши интересы или основные права и свободы, которые требуют защиты личных
      данных, в частности, когда субъектом данных является ребенок.
    </p>
    <h3>ПЕРСОНАЛЬНЫЕ ДАННЫЕ И ИНФОРМАЦИЯ, КОТОРЫЕ МЫ СОБИРАЕМ</h3>
    <p>
      Первая группа обрабатываемых нами персональных данных включает
      персональные данные, которые мы можем собирать автоматически. Мы можем
      собирать и анализировать информацию об устройствах и идентификаторы, такие
      как IP-адреса, информацию о местоположении (по странам и городам),
      уникальные идентификаторы устройств Apple ID для рекламы, статистическую
      информацию об использовании Программы, журналы ошибок и другую подобную
      информацию.
    </p>
    <p>
      Вторая группа персональных данных, которые мы обрабатываем, включает
      персональные данные, которые вы добровольно предоставляете нам с целью
      использования программы. Когда мы просим вас предоставить личные данные,
      вы можете отказаться. К таким персональным данным относятся: время
      рождения, дата рождения, место рождения Пользователя.
    </p>
    <p>
      Третья группа персональных данных включает в себя данные из внешних
      источников. Мы можем использовать сторонние инструменты, предоставляющие
      некоторые идентификационные данные, которые впоследствии применяются для
      настройки и персонализации Программы. Мы также можем использовать эти
      данные для сбора статистики и аналитики.
    </p>
    <p>
      В случае предоставления данных подразумевается, что вы предоставляете нам
      точную информацию о себе. Мы не обязаны проверять точность предоставленной
      информации.
    </p>
    <p>
      Несмотря на то, что любая личная информация, которую вы предоставляете,
      будет защищена настоящей Политикой конфиденциальности, за исключением
      случаев, когда у нас есть юридическое обязательство раскрыть ее, мы просим
      вас при использовании Программы не предоставлять информацию, касающуюся
      расового или этнического происхождения, политических убеждений, религии
      или философских убеждений, членства в профсоюзах, а также обработки
      генетических данных, данных, касающихся здоровья, или данных, касающихся
      половой жизни или осуждения преступлений, или связанных с этим мер
      безопасности. Если вы предоставите такую информацию по какой-либо причине,
      это будет ваше прямое согласие на сбор и использование такой информации.
    </p>
    <h3>ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
    <p>
      Программа обрабатывает вашу персональную информацию для надлежащей работы.
      Мы обрабатываем ваши персональные данные только для следующих целей:
    </p>
    <p>Развития Программы и предоставления возможности его использования.</p>
    <p>
      Для персонализации и обогащения предоставляемой информации, которая будет
      актуальна и интересна для вас.
    </p>
    <p>
      Для связи с вами, включая отправку уведомлений, запросов и информации об
      использовании Программы.
    </p>
    <p>
      Для проведения статистических и других исследований на основе анонимных
      данных.
    </p>
    <h3>УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
    <p>
      Предоставленная вами информация будет использоваться Программой в
      соответствии с условиями настоящей Политики.
    </p>
    <p>
      Мы храним ваши персональные данные до тех пор, пока это необходимо для
      целей обработки ваших персональных данных, определенных в настоящей
      Политике. Обработка персональных данных осуществляется без ограничения по
      времени.
    </p>
    <p>
      Ваши личные данные могут быть переданы уполномоченным государственным
      органам только в соответствии с действующим законодательством.
    </p>
    <p>
      Мы прекратим обработку ваших персональных данных в одном из следующих
      случаев:
    </p>
    <p>
      по достижении целей обработки или в случае потери необходимости достижения
      этих целей;
    </p>
    <p>
      по вашему запросу, если обработанные личные данные получены незаконным
      путем или больше не нужны для указанных целей обработки;
    </p>
    <p>
      по истечении срока вашего согласия на обработку персональных данных или в
      случае отзыва такого согласия, если нет иных законных оснований для
      обработки персональных данных;
    </p>
    <p>в случае прекращения деятельности Программы.</p>
    <h3>ПРАВА В ОТНОШЕНИИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
    <p>У Вас есть следующие права в отношении ваших персональных данных:</p>
    <p>
      Право доступа к вашим персональным данным. Вы имеете право доступа к своим
      персональным данным, которые мы храним о вас, вы можете узнать, какие
      персональные данные мы обрабатываем, отправив запрос на нашу электронную
      почту: linea@mixort.com . При определенных обстоятельствах и в
      соответствии с применимым законодательством вы имеете право получить копию
      ваших личных данных, предоставленных вами, в структурированном, широко
      используемом и машиночитаемом формате. Вам не придется платить за доступ к
      вашей личной информации (или для осуществления любых других прав). Тем не
      менее, МИКСОРТ может взимать разумную плату или отказываться выполнять ваш
      запрос, если он является явно необоснованным или повторяющимся.
    </p>

    <p>
      Право на изменение или исправление ваших персональных данных. Вы имеете
      право отправить нам запрос на изменение ваших персональных данных, если
      они, например, неполные или неверные. Чтобы внести изменения в ваши
      персональные данные, вам следует отправить запрос по электронной почте:
      linea@mixort.com .
    </p>
    <p>
      Право на отзыв согласия на обработку персональных данных. В тех случаях,
      когда мы обрабатываем ваши личные данные на основе вашего согласия, вы
      можете отозвать свое согласие в любое время. Чтобы отозвать свое согласие
      на обработку персональных данных, вам необходимо отправить соответствующий
      запрос на электронную почту: linea@mixort.com .
    </p>
    <p>
      Право ограничить обработку или запретить обработку персональных данных. У
      вас есть право ограничить или запретить обработку персональных данных в
      любое время по обоснованным причинам. После получения запроса на
      linea@mixort.com мы прекратим или ограничим обработку ваших персональных
      данных, за исключением случаев, когда у нас будут законные основания на
      обработку персональных данных, а также, если такая обработка будет
      необходима для осуществления или защиты наших прав.
    </p>
    <p>
      Другие пава субъектов персональных данных. Наравне с правами на доступ,
      изменение, ограничение или запрет на обработку, указанными выше, у вас
      есть и другие права в отношении тех персональных данных, которые мы
      храним, такие как право на удаление данных и право на перенос данных,
      право на подачу жалобы в орган защиты данных.
    </p>
    <p>
      Если у вас есть вопрос или вы хотите воспользоваться своими правами,
      свяжитесь с нами по электронной почте: linea@mixort.com . Мы сделаем все
      возможное, чтобы ответить на ваш обоснованный запрос в течение 30 дней.
      Иногда это может занять больше времени, если ваш запрос является особенно
      сложным или вы сделали несколько запросов. В любом случае мы уведомим вас
      о ходе рассмотрения запроса.
    </p>
    <h3>ДОПОЛНИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
    <p>
      МИКСОРТ защищает вашу личную информацию так же, как она защищает свою
      конфиденциальную информацию, например, хранит ее на защищенных серверах, к
      которым имеет доступ только определенные сотрудники, чтобы предотвратить
      доступ посторонних лиц. Мы соблюдаем общепризнанные международные
      стандарты. Мы и наши сотрудники понимаем необходимость обеспечения
      конфиденциальности пользователей и принимаем разумно обоснованные меры
      обеспечения безопасности для защиты ваших данных от потери, неправильного
      использования и несанкционированного доступа, раскрытия, изменения и
      уничтожения. В целях защиты личной информации мы знакомим наших
      сотрудников с рекомендациями по безопасности и конфиденциальности
      персональных данных и обеспечиваем их исполнение.
    </p>
    <p>
      Соглашаясь с условиями настоящей Политики, вы выражаете свое согласие на
      то, что собранные Персональные данные могут храниться и обрабатываться в
      Германии, где МИКСОРТ арендует серверы, или в любой другой стране, в
      которой располагаются наши объекты или филиалы, представительства,
      дочерние компании или агенты, и, принимая условия Политики
      конфиденциальности, вы соглашаетесь на любую такую передачу Персональных
      данных за пределы вашей страны.
    </p>
    <p>
      МИКСОРТ может периодически обновлять данную Политику. По этой причине мы
      рекомендуем вам время от времени проверять актуальность версии настоящей
      Политики. Новая редакция Политики вступает в силу с момента ее публикации.
      Продолжая пользоваться Приложением после изменения Политики, вы
      подтверждаете свое согласие с внесенными изменениями.
    </p>
    <p>
      Мы надеемся, что вам это не понадобится, но если вы хотите подать жалобу
      по поводу использования ваших персональных данных, отправьте электронное
      письмо с подробной информацией о жалобе на электронную почту:
      linea@mixort.com . Любые полученные жалобы будут рассмотрены, и мы ответим
      на них в течение 30 дней. Вы также имеете право подать жалобу в
      уполномоченный государственный орган.
    </p>
    <p>
      Если у вас есть какие-либо вопросы или предложения относительно данной
      Политики или вы хотите обратиться к нам с запросом, касающимся обработки
      ваших персональных данных, пожалуйста, отправьте нам электронное письмо по
      адресу электронной почты linea@mixort.com .
    </p>
  </div>
</div>
