<div class="footer-wrapper">
  <div class="button-wrapper" [@footer]>
    <a class="store-link" href="https://apps.apple.com/us/app/linea-your-places-on-earth/id1577370602" target="_blank">
      <app-button src='../assets/images/corrent.svg' text="App Store"></app-button>
    </a>
    <a class="store-link" href="https://play.google.com/store/apps/details?id=com.mixort.linea" target="_blank">
      <app-button src='../assets/images/google_play.svg' text="Play Store"></app-button>
    </a>
    <div class="links">
      <a routerLink='./terms'> ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ </a>
      <a routerLink='./privacy'>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</a>
    </div>
  </div>
</div>
