import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'] ,
  animations: [
    trigger('header', [
      transition('void => *', [
        style({opacity: 0, transform: 'translateY(40%)', }),
        animate(700, style({opacity: 1, transform: 'translateY(0)'})),
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit {

  activeLanguage: 'rus' | 'eng' = 'eng';
  ngOnInit(): void {
  }

}
